Vue.component('genericIndicatorForm',{
    props:['reportScope','reportName',"loading"],
    data:function(){
        let today = moment();
        return {
            running:false,
            selected:null,
            reportParams:{},
            id: this.getUI(),
            dateFormat:'YYYY-MM-DD'
        }
    },
    created:async function(){
        this.createInitialParameters();
        this.loadReportParams();
        await this.runReport();
    },
    updated:function(){
        //this.createInitialParameters();
        //$('select').material_select();
        this.loadReportParams();
    },
    computed:{
        params:function () {
            return this.loadReportParams()
        }
    },
    methods:{
        createInitialParameters(){
            let reportParams = {}
            let reportSpec = Object.values(this.$store.getters.getIndicatorParameters(this.reportName));

            for(let param of reportSpec){
                if(this.getEditor(param) =='date'){
                    reportParams[param.field]= this.getDate(param.default);
                }
                else if(param.fieldDef.component!='PeriodComponent'){
                    reportParams[param.field]= this.getValue(param.default);
                }
                else{
                    let periodConfig = this.getPeriodDate(param.default);
                    reportParams[param.field+'_from_']= periodConfig[0];
                    reportParams[param.field+'_to_']  = periodConfig[1];
                    reportParams[param.field+'_selector_']="9999";
                }
            }
            this.reportParams=reportParams;
        },
        getEditor: function (param) {
            this.isSet = false;
            //console.log(param,param.fieldDef.editor || param.fieldDef.type);
            switch (param.fieldDef.editor || param.fieldDef.type) {
                case 'string':
                    return 'string';
                case 'boolean':
                case 'checkbox':
                    return 'checkbox';
                case 'combobox':
                    return 'combobox';
                case 'period':
                    return 'period';
                case 'date':
                    return 'date';
                case 'matrix':
                    return 'matrix';
                case 'datepicker':
                    return 'date';
                case 'set':
                    return "string";
                case 'vuecomponent':
                    if(param.fieldDef.component=="PeriodComponent")
                        return "period";
            }
            return 'string'
        },
        getValue:function(defaultValue){
            switch (defaultValue) {
                case "@Customer.Code":
                    return this.$store.getters.getCustomer.Code || '';
                case "@Customer.GroupCode":
                    return this.$store.getters.getCustomer.GroupCode || '';
                case "@Customer.Gifts":
                    return this.$store.state.customerGifts.join(",");
             }
            return defaultValue;
        },
        getDate:function(defaultValue){
            let fromPeriod =moment().format(this.dateFormat);
            console.log('Calculate Date Default',defaultValue)
            if(defaultValue){
                let extracValue = defaultValue.match(/(\d+)/);
                switch (true) {
                    case /@LastMonth/.test(defaultValue):
                        fromPeriod =moment().subtract(1, 'months').format(this.dateFormat);
                        break;
                    case /@LastWeek/.test(defaultValue):
                        fromPeriod =moment().subtract(1, 'weeks').format(this.dateFormat);
                        break;
                    case /^@Last[0-9]*Year$/.test(defaultValue):
                    case /^@Last[0-9]*Years$/.test(defaultValue):
                        let months = 1;
                        if(extracValue)
                            months=extracValue[0];
                        fromPeriod =moment().subtract(months , 'years').format(this.dateFormat);
                        break;
                    case /^@Last[0-9]*Month$/.test(defaultValue):
                    case /^@Last[0-9]*Months$/.test(defaultValue):
                        let years = 1;
                        if(extracValue)
                            years=extracValue[0]
                        fromPeriod =moment().subtract(years, 'months').format(this.dateFormat);
                        break;
                    case /^@Last[0-9]*Day$/.test(defaultValue):
                    case /^@Last[0-9]*Days$/.test(defaultValue):
                        let days = 1;
                        if(extracValue)
                            days=extracValue[0];
                        fromPeriod =moment().subtract(days, 'days').format(this.dateFormat);
                        break;
                }
            }
            console.log('Default Date Value',fromPeriod);
            return fromPeriod;
        },
        getPeriodDate:function(defaultValue){
            let fromPeriod =moment().subtract(1, 'months').format(this.dateFormat);
            let toPeriod = moment().format(this.dateFormat);
            console.log('Calculate Date Default',defaultValue)
            if(defaultValue){
                let extracValue = defaultValue.match(/(\d+)/);
                switch (true) {
                    case /@LastMonth/.test(defaultValue):
                        fromPeriod =moment().subtract(1, 'months').format(this.dateFormat);
                        toPeriod = moment().format(this.dateFormat);
                        break;
                    case /@LastWeek/.test(defaultValue):
                        fromPeriod =moment().subtract(1, 'weeks').format(this.dateFormat);
                        toPeriod = moment().format(this.dateFormat);
                        break;
                    case /^@Last[0-9]*Year$/.test(defaultValue):
                    case /^@Last[0-9]*Years$/.test(defaultValue):
                        let months = 1;
                        if(extracValue)
                            months=extracValue[0];
                        fromPeriod =moment().subtract(months , 'years').format(this.dateFormat);
                        toPeriod = moment().format(this.dateFormat);
                        break;
                    case /^@Last[0-9]*Month$/.test(defaultValue):
                    case /^@Last[0-9]*Months$/.test(defaultValue):
                        let years = 1;
                        if(extracValue)
                            years=extracValue[0]
                        fromPeriod =moment().subtract(years, 'months').format(this.dateFormat);
                        toPeriod = moment().format(this.dateFormat);
                        break;
                    case /^@Last[0-9]*Day$/.test(defaultValue):
                    case /^@Last[0-9]*Days$/.test(defaultValue):
                        let days = 1;
                        if(extracValue)
                            days=extracValue[0]
                        fromPeriod =moment().subtract(days, 'days').format(this.dateFormat);
                        toPeriod = moment().format(this.dateFormat);
                        break;
                }
            }
            return [fromPeriod,toPeriod];
        },
        getReportName(){
            let splitReportName = this.reportName.split("_")
            return splitReportName[0]
        },
        loadReportParams(){
            //console.log('loadReportParams',this.$store.getters.getIndicatorParameters(this.reportName));
            return this.$store.getters.getIndicatorParameters(this.reportName);
        },
        runReport(){
          console.log('run with params',this.reportParams);
          let self = this;
          this.running=true;
          this.reportScope.runAction(this.reportParams)
              .then(function(result){
                  self.running=false;
              })
        },
        runExport(){
            console.log('export with params',this.reportParams);
            let self = this;
            this.running=true;
            this.reportScope.exportAction(this.reportParams).then(function(result){
                self.running=false;
            });
        }
    },
    template:`<div> 
                 <div class="row">   
                     <template v-for="param of params">
                        <template v-if="param.isEditable">
                            <template v-if="param.fieldDef.component!='PeriodComponent'">
                                <div class="col s6" > 
                                    <label class="active" v-if="getEditor(param) != 'period' & getEditor(param) != 'checkbox' " :for="param.field+'_'+id">{{tr(param.fieldDef.label)}}</label>        
                                    <input  v-if="getEditor(param) == 'string'"  :id="param.field+'_'+id" type="text"  v-model="reportParams[param.field]" placeholder="" :value="getValue(param.default)" @input="$emit('input',$event)" ref="input" >
                                    <input  v-else-if="getEditor(param) == 'checkbox'" type="checkbox" :id="param.field+'_'+id" v-bind:checked="getValue(param.default)" @change="$emit('input', $refs.input.checked)" ref="input">
                                    <input  v-else-if="getEditor(param) == 'date'"  type="date" :id="param.field+'_'+id"  v-model="reportParams[param.field]" :value="getDate(param.default)" ref="input">
                                    <material-select  :options="param.fieldDef.options" v-else-if="getEditor(param) == 'combobox'" :id="param.field+'_'+id"  :value="getValue(param.default)" @change="$emit('input', $refs.input.value)" ref="input" /> 
                                </div>
                            </template>
                            <template v-else>
                                 <div class="col s6" > 
                                    <label class="active"  :for="param.field+'_from_'+id">{{tr("From")}} {{tr(param.fieldDef.label)}}</label>        
                                    <input :id="param.field+'_from_'+id" type="date" v-model="reportParams[param.field+'_from_']" t placeholder="" :value="getPeriodDate(param.default)" @input="$emit('input',$event)" ref="input" > 
                                </div>
                                <div class="col s6" > 
                                    <label class="active"  :for="param.field+'_to_'+id">{{tr("To")}} {{tr(param.fieldDef.label)}}</label>        
                                    <input :id="param.field+'_to_'+id"  v-model="reportParams[param.field+'_to_']" type="date"  placeholder="" :value="getPeriodDate(param.default,)" @input="$emit('input',$event)" ref="input" > 
                                </div>
                            </template>
                        </template> 
                        <template v-else>
                            <input-hide :def="param.fieldDef" :value="getValue(param.default)" />
                        </template>
                     </template>
                     <div class="row col" class="col  s12">
                        <div class="col" v-bind:class="{disabled:running,s12:getReportName()!='StockList',s4:getReportName()=='StockList'}">
                            <label></label>
                            <button class="btn col s12" v-bind:class="{disabled:running}" @click="runReport">{{tr('Run')}}</button>
                        </div>
                     </div>
                 </div>
            </div>`
});